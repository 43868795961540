main {
  min-height: 80vh;
  background-color: white;
  /* background-color: rgba(240, 241, 243, 0.959); */
  /* background-color: rgba(249, 251, 253, 0.932); */
}

h3 {
  padding: 1rem 0;
}

h2 {
  font-size: 1.4rem;
  padding: 0.1rem;
}

h1 {
  font-size: 1.8rem;
  padding: 0.5rem 0;
}

.h55 {
  font-size: 1.2rem;
  font-weight: bold;
}

.h55light {
  font-size: 1rem;
}

.h6 {
  font-size: 1rem;
  font-weight: bold;
}

.h7 {
  font-size: 0.8rem;
  font-weight: bold;
}

.h8 {
  font-size: 0.6rem;
  font-weight: bold;
}

.datetime {
  font-size: 0.7rem;
  font-weight: bold;
}

.gradient-background {
  background: linear-gradient(to right, #bb5ced 50%, #d4c068 50%);
  /* background: white; */
  /* border-bottom:solid;
  border-color:'#a516f2'; */
  color:'black';
  font-family: "Copperplate", serif;
}

.header {

  padding: -10px;
}

.footer {
  /* background-color: #fcdbbf; */

  /* background-color: #d7e6f0 !important; */
  background-color: white !important;

  /* background-color: #f4a460; */
  color: black;
}

.rating_span {
  margin: 0.1rem;
}

.button_gen {
  background-color: #00416a;
  /* #00416a; */
  /* #1a5276; */
  /* #ff9933; */
  /* #1a5276; */
}

.button_channel_side {
  background-color: #00416a;
  /* #1a5276; */
}

.button_qanda {
  background-color: #00416a;
  /* #1a5276; */
  /* #006600; */
}

.body {
  display: flex;
  height: 90vh;
  border-style: hidden;
}

.sidenav {
  width: 25vw;
  height: 90vh;
  border-style: none;
  border-width: 1px;
  padding: 10px;
  margin: 3px;
  overflow: scroll;
  background-color: white;
  border-radius: 7px;
}

.content {
  height: 90vh;
  border-style: none;
  border-width: 1px;
  padding: 10px;
  margin: 3px;
  width: 75vw;
  overflow: scroll;
  background-color: white;
  border-radius: 7px;
}



.mobilttopic {
  height: 95vh;
  border-style: none;
  border-width: 1px;
  padding: 10px;
  margin: 3px;
  overflow: scroll;
  background-color: white;
  border-radius: 7px;
}

.mobilesession {
  height: 95vh;
  border-style: none;
  border-width: 1px;
  padding: 10px;
  margin: 3px;
  overflow: scroll;
  background-color: white;
  border-radius: 7px;
}

.content_right {
  height: 90vh;
  border-style: none;
  border-width: 1px;
  padding: 10px;
  margin: 3px;
  width: 40vw;
  overflow: scroll;
  background-color: white;
  border-radius: 7px;
}

.homescreen_product {
  height: 10vh;
  width: 10vw;
}

.homescreen_product_image {
  height: 10vh;
  width: 10vw;
}

.cardtag {
  border-style: solid;
  background: rgb(76, 175, 80, 0.1);
}

.lecturecardwindow {
  height: 30vh;
  border-style: solid;
  padding: 10px;
  width: 80vw;
  overflow: scroll;
}

.jitsi-container {
  height: 70vh;
}

.content_live_lecture {
  height: 90vh;
  border-style: solid;
  padding: 0px;
  width: 80vw;
  overflow: scroll;
}

.content_right_live_lecture {
  height: 90vh;
  border-style: solid;
  padding: 10px;
  width: 20vw;
  overflow: scroll;
}

.jitsivideolocation {
  height: 70vh;
  border-style: solid;
  padding: 10px;
  width: 78vw;
  overflow: scroll;
}

.live_lecture_notes {
  height: 20vh;
  border-style: solid;
  padding: 10px;
  width: 78vw;
  overflow: scroll;
}

.admin_body {
  display: flex;
  height: 95vh;
}

.admin_content {
  height: 95vh;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  width: 60vw;
  overflow: scroll;
}

.admin_content_right {
  height: 95vh;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  width: 40vw;
  overflow: scroll;
}

.admin_sidenav {
  width: 20vw;
  height: 95vh;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  overflow: scroll;
}

img {
  padding: 10px;
}

.tylogo {
  padding: -10px;
  margin: -10px;
  position: relative;
  top: -10px;
}

.card_ty_main {
  border-style: None;
  border-width: 1px;
  /* background-color: rgb(254, 245, 231); */
  margin: 5px;
  /* border-radius: 10px; */
  /* border-color: rgb(254, 245, 231); */
}

.card_qanda {
  border-style: None;
  border-width: 1px;
  border-color: black;
  /* background-color: rgb(234, 250, 241); */
  background-color: white;
  border-radius: 5px;
}

.card_qanda_body {
  background-color: white;
  border-radius: 10px;
}

.card_channel {
  border-style: solid;
  border-width: 3px;
  border-color: black;
  /* background-color: #dfe7e7; */

  background-color: #fcdbbf;

  /* #e6f2ff; */
  /* rgb(245, 238, 248); */
  margin: 1px;
  margin-bottom: 3px;
  border-radius: 10px;
}

.card_channel_body {
  background-color: white;
  /* border-radius: 10px; */
}

.channel_header {
  font-size: 15px;
  font-weight: bold;
}

.card_group {
  border-style: None;
  border-width: 1px;
  border-color: black;

  /* background-color: #fad8ba; */
  /* background-color: #dfe7e7; */
  background-color: #fcdbbf;

  /* #e6f2ff; */
  /* rgb(245, 238, 248); */
  margin: 1px;
  margin-bottom: 3px;
  /* border-radius: 10px; */
}

.card_groupview {
  border-style: solid;
  border-width: 1px;
  border-color: black;
  /* background-color: #dfe7e7; */

  /* background-color: #dfe7e7; */
  background-color: #fcdbbf;

  /* #e6f2ff; */
  /* rgb(245, 238, 248); */
  margin: 1px;
  margin-bottom: 3px;
  border-radius: 10px;
  /* width: 20vw; */
  height: 25vh;
}

.card_home_student {
  background-color: #d58ae7;
  padding-top: 2px;
  /* #e6f2ff; */
  border-radius: 15px;
}

.card_home_teacher {
  background-color: #48d1cc;
  /* #e6f2ff; */
  border-radius: 15px;
}

.card_home_aboutus {
  background-color: #729dec;
  border-radius: 15px;
}

.card_home_pricing {
  background-color: #7ff1c2;
  border-radius: 15px;
}

.card_home_header {
  text-align: center;
  font-weight: 900;
  border-radius: 15px;
}

.card_contactus_header {
  text-align: center;
  font-weight: 900;
  border-radius: 15px;
  border-style: solid;
  border-width: 2px;
  border-color: black;
}

.board_card {
  padding: 5px;
  padding-top: 10px;
  border-color: black;
}

.btn-circle {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 10px;
  line-height: 1.33;
}

.recommend_card {
  padding: 5px;
  padding-top: -10px;
  border-color: black;
  background-color: #e5aef3;
  border-style: solid;
  border-width: 1px;
  font-weight: bold;
  color: black;
}

.card_qanda_comment {
  border-style: none;
  border-width: 1px;
  border-color: black;

  /* border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: grey; */

  /* #e6f2ff; */
  /* rgb(245, 238, 248); */
  margin: 1px;
  margin-bottom: 3px;
  border-radius: 3px;
  background-color: rgb(234, 250, 241);
  border-radius: 5px;
}

.card_qanda_comment_text {
  border-style: solid;
  border-width: 1px;
  border-color: black;
  /* #e6f2ff; */
  /* rgb(245, 238, 248); */
  margin: 1px;
  margin-bottom: 3px;
  border-radius: 3px;
}

.card_supportm {
  border-style: solid;
  border-width: 1px;
  border-color: black;
  margin: 1px;
  margin-bottom: 3px;
  border-radius: 1px;
  padding: 5px;
}

.form_support {
  border-style: solid;
  border-width: 1px;
  border-color: black;
  margin: 1px;
  margin-bottom: 3px;
  border-radius: 1px;
  padding: 10px;
  background-color: #f3eecf;
}

.sidenav_mob {
  width: 98vw;
  height: 90vh;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  overflow: scroll;
}

.content_mob {
  width: 98vw;
  height: 90vh;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  overflow: scroll;
}

.content_right_mob {
  width: 98vw;
  height: 90vh;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  overflow: scroll;
}

.button_mob_selector {
  font-size: 10px;
}

.notes_image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

/* .button_micro {
  width: 10px;
  height: 10px;
} */

.subscription_card {
  border-style: solid;
  border-width: 1px;
  border-color: black;
  margin: 1px;
  margin-bottom: 3px;
  border-radius: 1px;
  padding: 0.5px;
}

.navbarButton {
  background-color: white;
  color: #174663;
}

.trialColor {
  background-color: rgb(253, 245, 239);
}

.navbarlink {
  /* color: white !important; */
  /* color: black !important; */
  color: #00416a !important;
  border-style: "solid";
}

.nav a {
  color: black !important;
}

.gcourse {
  margin: 7px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  /* background-color: #dfe7e7; */
  /* background-color: #d2db4a; */

  background-color: #fcdbbf;

  border-radius: 10px;
}

.gpost {
  margin: 7px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  /* background-color: #dfe7e7; */
  /* background-color: #d2db4a; */
  background-color: #fcdbbf;

  /* background-color: #fce8d6; */

  border-radius: 10px;
}

.gpost_header {
  margin: 7px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  /* background-color: #dfe7e7; */
  background-color: #d2db4a;
  border-radius: 10px;
}

.gpost_body {
  background-color: white;
  /* border-radius: 10px; */
}

.dashGS_left {
  width: 48vw;
  height: 90vh;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  overflow: scroll;
}

.dashGS_right {
  height: 90vh;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  width: 48vw;
  overflow: scroll;
}

.grouppage_main {
  width: 80vw;
}

.admingroup_left {
  width: 65vw;
  height: 90vh;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  overflow: scroll;
}

.admingroup_right {
  width: 35vw;
  height: 90vh;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  overflow: scroll;
}

.grouppalette_card {
  margin: 7px;
  border-style: none;
  border-width: 3px;
  border-color: black;
  background-color: #174663;
  border-radius: 10px;
}

.palette_items {
  background-color: white;
}

.studentexam_body {
  display: flex;
  height: 99vh;
  border-style: hidden;
}

.studentexam_paper {
  width: 75vw;
  height: 99vh;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  overflow: scroll;
}

.studentexam_answersheet {
  height: 99vh;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  width: 25vw;
  overflow: scroll;
}

.studentexam_body_Mob {
  display: flex;
  height: 99vh;
  border-style: hidden;
}

.studentexam_paper_Mob {
  width: 95vw;
  height: 99vh;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  overflow: scroll;
}

.studentexam_answersheet_Mob {
  height: 99vh;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  width: 80vw;
  overflow: scroll;
}

.studentexamsubmissions_body {
  display: flex;
  height: 99vh;
  border-style: hidden;
}

.studentexamsubmissions_list {
  width: 25vw;
  height: 99vh;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  overflow: scroll;
}

.studentexamsubmissions_answersheet {
  height: 99vh;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  width: 75vw;
  overflow: scroll;
}

.card_front {
  border-style: solid;
  border-width: 1px;
  border-color: black;
  background-color: #174663;
  color: white;
  /* #e6f2ff; */
  /* rgb(245, 238, 248); */
  margin: 1px;
  margin-bottom: 3px;
  border-radius: 10px;
  text-align: center;
}

.card_front_body {
  background-color: white;
  border-radius: 10px;
  color: black;
}

.card_register {
  /* background-color: #f5deb3; */
  background-color: #174663;
  /* #e6f2ff; */
  border-radius: 15px;
  color: white;
  border-style: solid;
  border-width: 1px;
  border-color: black;
}

.myGroup_left {
  width: 25vw;
  height: 90vh;
  border-style: none;
  border-width: 1px;
  padding: 10px;
  overflow: scroll;
}

.myGroup_right {
  height: 90vh;
  border-style: none;
  border-width: 1px;
  padding: 10px;
  width: 75vw;
  overflow: scroll;
}

.card_frontnext {
  border-style: None;
  border-width: 1px;
  border-color: black;
  /* background-color: #b60ce0; */
  /* background-color: #174663; */

  /* background-color: #d2db4a; */

  /* background-color: #dadf97; */

  /* background-color: #dde0b0; */
  /* background-color: #f7933b; */

  /* color: white; */
  /* #e6f2ff; */
  /* rgb(245, 238, 248); */
  margin: 1px;
  margin-bottom: 5px;
  /* border-radius: 10px; */
  /* text-align: center; */
}

.card_frontnext_body {
  background-color: white;
  border-radius: 10px;
  color: black;
}

.card_frontnext_header {
  text-align: center;
  font-weight: 900;
  border-radius: 15px;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-caption {
  position: absolute;
  top: 0;
}

.carousel-caption h2 {
  color: #fff;
}

.carousel img {
  height: 300px;
  padding: 30px;
  margin: 40px;
  border-radius: 50%;
}

.carousel a {
  margin: 0 auto;
}



.quiz_home {
  /* border-style: solid;
  border-width: 1px;
  border-color: black; */

  /* background-color: #bbf386; */
  /* background-color: #f7f595; */
  /* background-color: #a5bbf8; */

  background-color: white;
  border-style: None;

  /* background-color: #fcdbbf; */
}


.puzzle_rows{
  margin:'0px' !important;
  padding:'0px' !important;
}

.puzzle_columns{
  margin:'0px' !important;
  padding:'0px' !important;
}

tr td{
  padding: 0 !important;
  margin: 0 !important;
}

.keyboardP {
  border-radius:10px; 
  border:2px solid #8b008b; 
  background-color:#f0f0f5;
  color:black;
  margin: 1px;
  padding:1.5vh;
  padding-left:2.0vh;
  padding-right:2.0vh;
  

}


.keyboardPMob {
  border-radius:10px; 
  border:2px solid #8b008b; 
  background-color:#f0f0f5;
  color:black;
  margin: 1px;
  padding:1.5vw;
  padding-left:2.5vw;
  padding-right:2.5vw;
  

}

.gridP {
  text-align:center;
  width:33%;
  padding-bottom: 33%;
  height:0;
  left:1vw;
}


.correctTextColor {
  /* color: #796808; */
  /* color: #087b08; */
  color: #075d2b;
}

.tyzlleCarouselColor {
  color: #42075d;
}


.smartlearnbuttons {
  background-color: #c2179a;
  margin:'2px';
  border-radius:'10px';
}

.tablestyle {

  border-collapse: collapse;
  border-spacing: 0;
  /* width: 100%; */
  /* border: 1px solid #ddd; */
  /* overflow-x:auto; */
}

/* th, td {
  text-align: left;
  padding: 8px;
  margin:10px;
} */

/* td {
  width: 10%;
  min-width:50px;
  font-weight: 600;
} */


.about-us-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.about-header {
  background-color: #4CAF50;
  color: white;
  text-align: center;
  padding: 20px 0;
}

.about-intro, .about-mission, .about-features, .about-team {
  margin-bottom: 40px;
}

.about-team .team-members {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.team-member {
  width: calc(25% - 20px); /* Adjust based on your design */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

.team-member img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.about-footer {
  text-align: center;
  padding: 20px 0;
  background-color: #f4f4f4;
}
